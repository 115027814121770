import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import axios from "axiosClient";

const SendRequest = (props) => {
  const { toggle, candidate, handleAfterSendRequest } = props;

  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `/api/partner-request/${candidate._id}`,
        { comment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          setErrorMessage("");
          setSuccess(true);
          toast.success("Successfully sent");
          handleAfterSendRequest();
        } else {
          toast.error("Something went wrong.");
        }
      })
      .catch((err) => {
        // console.log(err.response.data);
        setErrorMessage(Object.values(err.response.data.errors)[0]);
        toast.error("Something went wrong.");
      });
  };

  return (
    <div>
      {!loading && (
        <Row className="text-left">
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <div className="mb-2 text-center">
                  <h1>Request a Free Seat</h1>
                  <br />
                  <h1>
                    for {candidate.firstName} {candidate.lastName}
                  </h1>
                </div>
                <Separator className="mb-5" />
                <Form>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label>Other Details or Comments</Label>
                        <Input
                          type="textarea"
                          name="details"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <div>
                  <Button
                    color="light"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>

                  <Button
                    color={success ? "success" : "primary"}
                    className="top-right-button ml-2"
                    onClick={handleSubmit}
                    disabled={success}
                  >
                    {success ? "Done" : `Send`}
                  </Button>
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default SendRequest;
