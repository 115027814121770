import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Card,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import { useToken } from "auth/useToken";
import axios from "axiosClient";
import { useUser } from "auth/useUser";
import IconCard from "components/cards/IconCard";
import GradientWithRadialProgressCard from "components/cards/GradientWithRadialProgressCard";
import { useNavigate } from "react-router-dom";
import SendRequest from "components/sendModal";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";
import { reactSelectChange } from "utils/reactSelectChange";

const CandidatesList = () => {
  const [data, setData] = useState([]);
  const [cardValues, setCardValues] = useState({
    paid: null,
    awaiting: null,
    cancelled: null,
  });
  const [candidate, setCandidate] = useState({});
  const [partner, setPartner] = useState({});
  const [count, setCount] = useState(null);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [availableForBonus, setAvailaleForBonus] = useState(false);
  const [bonuses, setBonuses] = useState(0);
  const [sendModal, setSendModal] = useState(false);
  const [exam, setExam] = useState("TKT");
  const [ceqsLvl, setCeqsLvl] = useState(null);
  const [searchValues, setSearchValues] = useState({});
  const [regions, setRegions] = useState([]);
  const [certRegions, setCertRegions] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [sessions, setSessions] = useState([]);

  const [token, setToken] = useToken();
  const user = useUser();
  const navigate = useNavigate();

  const cardsData = [
    { title: "Paid", icon: "iconsminds-yes", value: cardValues.paid },
    {
      title: "Awaiting Payment",
      icon: "iconsminds-synchronize-2",
      value: cardValues.awaiting,
    },
    {
      title: "Cancelled",
      icon: "iconsminds-close",
      value: cardValues.cancelled,
    },
  ];

  const toggleSendModal = () => {
    setSendModal(!sendModal);
  };

  const handleClickCandidate = (details) => {
    setCandidate(details);
    toggleSendModal();
  };

  const handleAfterSendRequest = () => {
    setBonuses(bonuses - 1);
    if (bonuses - 1 === 0) {
      setAvailaleForBonus(false);
    }
  };

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearchValueChange = (e) => {
    setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
  };

  const handleSearchValueRegionChange = (e) => {
    // console.log(reactSelectChange(e));
    setSearchValues({ ...searchValues, region: reactSelectChange(e) });
  };
  const handleSearchValueCertRegionChange = (e) => {
    // console.log(reactSelectChange(e));
    setSearchValues({ ...searchValues, regionLive: reactSelectChange(e) });
  };

  const handleSearchValueStatusChange = (e) => {
    setSearchValues({ ...searchValues, status: reactSelectChange(e) });
  };
  const handleSearchValueSessionChange = (e) => {
    setSearchValues({ ...searchValues, examDate: reactSelectChange(e) });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    ///
    axios
      .post(
        `/api/partner-candidates`,
        { ...searchValues, page: 1, partner: user?.organization, exam },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.candidates);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);
        setLoading(false);
      });
  };

  const handleLoadMore = () => {
    let postData = {
      partner: user?.organization,
      page: page + 1,
      exam,
      ...searchValues,
    };

    axios
      .post(`/api/partner-candidates`, postData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        setData([...data, ...result.data.candidates]);
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(page + 1);
        setLoading(false);
      });
  };

  const handleCopyClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Successfully copied");
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };

  if (sendModal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const loadData = (examName, ceqLvl) => {
    setExam(examName);
    setCeqsLvl(ceqLvl);
    setLoading(true);
    axios
      .post(
        `/api/partner-candidates`,
        { partner: user?.organization, exam: examName, ceqLvl },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        setData(result.data.candidates);
        setPartner(result.data.partner);
        setCardValues(result.data.cardValues);
        let threshold =
          examName === "TKT"
            ? result.data.partner.thresholdTkt
            : ceqLvl === "A1B1"
            ? result.data.partner.thresholdCeqA1B1
            : ceqLvl === "B2C2"
            ? result.data.partner.thresholdCeqB2C2
            : result.data.partner.threshold;
        let bonusesApi =
          Math.floor(result.data.cardValues.paidForBonuses / threshold) -
          result.data.cardValues.usedBonuses -
          (examName === "TKT"
            ? result.data.partner.usedBonuses
            : ceqLvl === "A1B1"
            ? result.data.partner.usedBonusesCeqA1B1
            : ceqLvl === "B2C2"
            ? result.data.partner.usedBonusesCeqB2C2
            : result.data.partner.usedBonusesOther);

        setBonuses(bonusesApi || 0);
        if (bonusesApi > 0) {
          setAvailaleForBonus(true);
        } else {
          setAvailaleForBonus(false);
        }
        setCount(result.data.total);
        setPages(result.data.pageCount);
        setPage(result.data.page);

        let tempRegions = [];
        result.data.venues.forEach((region) =>
          tempRegions.push({ label: region, value: region })
        );
        setRegions(tempRegions);

        let tempCertRegions = [];
        result.data.certRegions.forEach((region) =>
          tempCertRegions.push({ label: region, value: region })
        );
        setCertRegions(tempCertRegions);

        let tempStatuses = [];
        result.data.statuses.forEach((status) =>
          tempStatuses.push({ label: status, value: status })
        );
        setStatuses(tempStatuses);

        let tempSessions = [];
        result.data.examDates.forEach((session) =>
          tempSessions.push({ label: session, value: session })
        );
        setSessions(tempSessions);

        setLoading(false);
      });
  };

  const getNumbers = (exam, partner, cardValues) => {
    let threshold =
      exam === "TKT"
        ? partner.thresholdTkt
        : ceqsLvl === "A1B1"
        ? partner.thresholdCeqA1B1
        : ceqsLvl === "B2C2"
        ? partner.thresholdCeqB2C2
        : partner.threshold;
    return {
      detail: threshold - (cardValues.paidForBonuses % threshold),
      percent: ((cardValues.paidForBonuses % threshold) * 100) / threshold,
      progress: `${cardValues.paidForBonuses % threshold}/${threshold}`,
    };
  };

  useEffect(() => {
    if (!user?._id) {
      navigate("/");
    } else {
      user._id && loadData("TKT");
    }
  }, []);

  return (
    <>
      <Row className="icon-cards-row mb-4">
        <Col lg="12">
          {/* <p>{JSON.stringify(user)}</p> */}
          <Row>
            <Button
              outline={exam !== "TKT"}
              color="primary"
              onClick={() => loadData("TKT")}
            >
              TKT
            </Button>
            <Button
              className="ml-2"
              outline={exam !== "Linguaskill"}
              color="primary"
              onClick={() => loadData("Linguaskill")}
            >
              Linguaskill
            </Button>
            <Button
              className="ml-2"
              outline={ceqsLvl !== "A1B1"}
              color="primary"
              onClick={() => loadData("CEQs", "A1B1")}
            >
              CEQs (A1-B1)
            </Button>
            <Button
              className="ml-2"
              outline={ceqsLvl !== "B2C2"}
              color="primary"
              onClick={() => loadData("CEQs", "B2C2")}
            >
              CEQs (B2-C2)
            </Button>
          </Row>
          <div className="loading" hidden={!loading} />
          {!loading && (
            <>
              <Row>
                {cardsData.map((cardsData, index) => (
                  <Col key={index} md="2" xs="4">
                    <IconCard {...cardsData} className="m-2" />
                  </Col>
                ))}
                <Col md="6" xs="12" className="mt-2 mb-2">
                  <GradientWithRadialProgressCard
                    icon="iconsminds-network"
                    title={`Available Free Seats: ${bonuses}`}
                    detail={`${
                      getNumbers(exam, partner, cardValues).detail
                    } remaining for the next free seat`}
                    detail2={`${cardValues.usedBonuses} used bonuses`}
                    percent={getNumbers(exam, partner, cardValues).percent}
                    progressText={
                      getNumbers(exam, partner, cardValues).progress
                    }
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <p style={{ textAlign: "center" }}>
                    <strong>Your Booking Link:</strong>{" "}
                    {`https://edu-action.uk/?pr=${partner.organization}`}{" "}
                    <span
                      className="clickable"
                      onClick={() =>
                        handleCopyClipboard(
                          `https://edu-action.uk/?pr=${partner.organization}`
                        )
                      }
                    >
                      <i
                        style={{ fontSize: 20 }}
                        className="iconsminds-duplicate-layer"
                      />
                    </span>
                  </p>
                  <p style={{ textAlign: "center" }}>
                    <strong>Telegram Booking Link:</strong>{" "}
                    {`https://t.me/tktregbot?start=pr=${partner.organization}`}{" "}
                    <span
                      className="clickable"
                      onClick={() =>
                        handleCopyClipboard(
                          `https://t.me/tktregbot?start=pr=${partner.organization}`
                        )
                      }
                    >
                      <i
                        style={{ fontSize: 20 }}
                        className="iconsminds-duplicate-layer"
                      />
                    </span>
                  </p>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {!loading && (
        <>
          <Row>
            <Colxx xxs="12">
              <div className="mb-2">
                <h1>Candidates List</h1>
                <div
                  className="text-zero top-right-button-container"
                  style={{ display: "flex" }}
                ></div>
              </div>
              <Form
                onSubmit={(e) => {
                  handleSearch(e);
                }}
              >
                <Row className="text-left">
                  <Col md="2">
                    <FormGroup>
                      <Label>Enquiry ID</Label>
                      <Input
                        type="number"
                        name="enquiryId"
                        value={searchValues.enquiryId}
                        onChange={handleSearchValueChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input
                        type="text"
                        name="firstName"
                        value={searchValues.firstName}
                        onChange={handleSearchValueChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        name="lastName"
                        value={searchValues.lastName}
                        onChange={handleSearchValueChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="text"
                        name="email"
                        value={searchValues.email}
                        onChange={handleSearchValueChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input
                        type="text"
                        name="phoneNumber"
                        value={searchValues.phoneNumber}
                        onChange={handleSearchValueChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>Passport</Label>
                      <Input
                        type="text"
                        name="passportId"
                        value={searchValues.passportId}
                        onChange={handleSearchValueChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>Region</Label>
                      <Select
                        // value={{
                        //   label: searchValues.region,
                        //   value: searchValues.region,
                        // }}
                        options={regions}
                        onChange={handleSearchValueRegionChange}
                        isMulti
                      />
                    </FormGroup>
                  </Col>

                  <Col md="2">
                    <FormGroup>
                      <Label>Certificate Region</Label>
                      <Select
                        // value={{
                        //   label: searchValues.region,
                        //   value: searchValues.region,
                        // }}
                        options={certRegions}
                        onChange={handleSearchValueCertRegionChange}
                        isMulti
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>Session</Label>
                      <Select
                        // value={{
                        //   label: searchValues.examDate,
                        //   value: searchValues.examDate,
                        // }}
                        options={sessions}
                        onChange={handleSearchValueSessionChange}
                        isMulti
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label>Status</Label>
                      <Select
                        // value={{
                        //   label: searchValues.status,
                        //   value: searchValues.status,
                        // }}
                        options={statuses}
                        onChange={handleSearchValueStatusChange}
                        isMulti
                      />
                    </FormGroup>
                  </Col>

                  <Col md="3 d-flex mt-1 mb-2">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={handleSearch}
                      style={{ height: "fit-content" }}
                    >
                      Search
                    </Button>
                    <span className="ml-2 mt-2"> Total: {count}</span>
                  </Col>
                </Row>
              </Form>
              <Separator className="mb-5" />
            </Colxx>
          </Row>
          <Row>
            <div className="loading" hidden={!loading} />
            {count === null && (
              <p>Please search for the data from the input above. </p>
            )}
            {!loading && count !== null && data.length === 0 ? (
              <p> List is empty</p>
            ) : (
              data.map((user) => (
                <Colxx xxs="12" key={user._id} className="mb-3">
                  <Card
                    className={`d-flex flex-row ${
                      user.requestedFreeSeat ? "processing-candidate" : ""
                    }`}
                  >
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                        <span className="w-10 w-sm-100 clickable">
                          <p className="mb-1 text-small">{user.enquiryId}</p>
                        </span>
                        <span className="w-30 w-sm-100 clickable">
                          <p className="list-item-heading mb-1 truncate">
                            {user.firstName} {user.lastName}
                          </p>
                        </span>
                        <span className="w-15 w-sm-100 clickable">
                          <p className="mb-1 text-small">{user.passportId}</p>
                        </span>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {user.status}
                        </p>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          Exam: {moment(user.examDate).format("DD/MM/YYYY")}
                        </p>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          Region: {user.region} <br />
                          Certificate: {user.regionLive}
                        </p>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {`${user.exam} | ${user.module}`}
                        </p>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {`${user.result ? user.result : ""}`}
                        </p>

                        {user.status.includes("Processing") &&
                          !user.requestedFreeSeat && (
                            <p
                              className={`w-10 w-sm-100 clickable list-item-heading`}
                            >
                              <Button
                                size="xs"
                                outline={user.status.includes("Processing")}
                                disabled={!user.status.includes("Processing")}
                                onClick={() =>
                                  window.open(
                                    `https://pay.xazna.uz/billing?merchantId=7fa8652e-b9a5-49e5-a50f-e1819f68e7a9&invoice=${user.enquiryId}`,
                                    "_blank"
                                  )
                                }
                              >
                                <i className={"iconsminds-credit-card"} /> Pay
                              </Button>
                            </p>
                          )}
                        {availableForBonus && (
                          <p
                            className={`w-10 w-sm-100 clickable list-item-heading ${
                              !user.status.includes("Processing")
                                ? "text-muted"
                                : ""
                            }`}
                          >
                            {user.status.includes("Processing") &&
                              !user.requestedFreeSeat && (
                                <Button
                                  size="xs"
                                  outline={!user.status.includes("Processing")}
                                  disabled={
                                    !(
                                      user.status.includes("Processing") &&
                                      availableForBonus
                                    )
                                  }
                                  onClick={() => handleClickCandidate(user)}
                                >
                                  <i className={"iconsminds-target-market"} />{" "}
                                  Free seat
                                </Button>
                              )}
                          </p>
                        )}
                      </div>
                    </div>
                  </Card>
                </Colxx>
              ))
            )}
            <Colxx xxs="12" className="mb-3">
              {!loading && data.length !== 0 && pages !== page && (
                <div className="col-12">
                  <Button
                    color="primary"
                    className="top-right-button"
                    onClick={handleLoadMore}
                  >
                    More...
                  </Button>
                </div>
              )}
            </Colxx>
          </Row>
        </>
      )}
      {sendModal && (
        <div className="in-modal">
          <div className="modal-overlay"></div>
          <div className="in-modal-content">
            <SendRequest
              toggle={toggleSendModal}
              candidate={candidate}
              handleAfterSendRequest={handleAfterSendRequest}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CandidatesList;
