import React, { useEffect, useState } from "react";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import { toast } from "react-toastify";

import { useToken } from "auth/useToken";
import { useUser } from "auth/useUser";
import axios from "axiosClient";

const EditPartner = (props) => {
  const { toggle, partnerId } = props;

  const initialValues = {
    fullName: "",
    organization: "",
  };

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const [success, setSuccess] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleChange = (e) => {
    setUpdated(true);
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let postData = { ...values };
    if (postData.password === "") delete postData.password;
    if (postData?.password?.length < 6) {
      toast.error("Password length should be minimum 6 charcters");
    } else {
      axios
        .post(`/api/partner/${partnerId}`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          // console.log(result);
          if (result.status === 200) {
            setErrorMessage("");
            setSuccess(true);
            toast.success(
              partnerId ? "Successfully updated" : "Successfully added"
            );
          } else {
            toast.error("Something went wrong. Is the telegram ID entered?");
          }
        })
        .catch((err) => {
          // console.log(err.response.data);
          setErrorMessage(Object.values(err.response.data.errors)[0]);
          toast.error(
            "Something went wrong. Is the telegram ID entered? (err)"
          );
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (partnerId) {
      axios
        .get(`/api/partner/${partnerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setValues(result.data);
          setLoading(false);
        })
        .catch((e) => {
          toast.error("something went wrong");
        });
    } else {
      setValues(initialValues);
      setLoading(false);
    }
  }, [partnerId]);

  return (
    <div>
      <Row>
        <Colxx xxs="12">
          <div className="mb-2">
            <h1>{partnerId ? "Update Details" : "Add Partner"}</h1>
          </div>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      {!loading && (
        <Row className="text-left">
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <Form>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>Full Name</Label>
                        <Input
                          type="text"
                          name="orgStaff"
                          value={values.orgStaff}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Telegram ID</Label>
                        <Input
                          type="number"
                          name="telegramId"
                          value={values.telegramId}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Password</Label>
                        <Input
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <div>
                  <Button
                    color="primary"
                    className="top-right-button"
                    onClick={toggle}
                  >
                    Close
                  </Button>
                  {updated && (
                    <Button
                      color={success ? "success" : "primary"}
                      className="top-right-button ml-2"
                      onClick={handleSubmit}
                      disabled={success}
                    >
                      {success ? "Done" : `${partnerId ? "Update" : "Add"}`}
                    </Button>
                  )}
                </div>
                <div>{errorMessage}</div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      )}
    </div>
  );
};

export default EditPartner;
