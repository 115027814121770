/* eslint-disable react/no-array-index-key, react/no-danger */
import React from "react";
import { Row } from "reactstrap";
import AppLayout from "layout/AppLayout";
import { Colxx } from "components/common/CustomBootstrap";
import CandidatesList from "./candidatesList";

const Home = () => {
  return (
    <AppLayout>
      <Row>
        <Colxx lg="12" xl="12" className="mb-4">
          <CandidatesList />
        </Colxx>
      </Row>
    </AppLayout>
  );
};

export default Home;
