import axios from "axios";

const axiosClient = axios.create({
  // baseURL: "http://localhost:6002",
  baseURL: "https://tktapi.estudent.uz/",
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 403) {
    //   localStorage.removeItem("token");
    //   window.location.href = "/login";
    // }
    throw error;
  }
);

export default axiosClient;
